<template>
  <product-form @submit="create" />
</template>
<script>
import { mapState, mapActions } from 'vuex'
import navigationMixin from '@/mixins/navigation'
import ProductForm from '@/components/Product/ProductForm'
import { buildProductUploadUrls } from '@/shared/file'

export default {
  mixins: [navigationMixin],
  components: {
    ProductForm
  },
  computed: {
    ...mapState('shared', ['error']),
    ...mapState('file', ['files'])
  },
  methods: {
    ...mapActions('product', ['createProduct']),
    ...mapActions('file', ['saveFiles']),
    ...mapActions('notification', ['showNotification']),
    async create (product) {
      const response = await this.createProduct(product)
      if (this.error) {
        this.showNotification({ message: this.error.message, color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
        return
      }

      if (this.files.length > 0) {
        const uploadUrlInput = buildProductUploadUrls(response.product_id, this.files)
        await this.saveFiles(uploadUrlInput)

        if (this.error) {
          this.showNotification({ message: 'Ocurrió un error al subir las imágenes, por favor inténtelo nuevamente', color: 'var(--app-popup-bg-color-danger)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
          this.goToRouteByName('update-product', { id: response.product_id })
          return
        }
      }

      this.showNotification({ message: 'Producto creado exitosamente.', color: 'var(--app-popup-bg-color-success)', y: 'top', x: 'right', buttonColor: '#FFFFFF' })
      this.goToRouteByName('products')
    }
  }
}
</script>
